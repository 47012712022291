<template>
  <div>
    <div
      class="border border-newAsh rounded-3xl bg-white pt-10 pb-40 px-3 lg:px-36"
    >
      <div v-if="publicPage" class="flex items-center justify-between">
        <div class="text-grey font-bold text-2xl mb-5 md:mb-0">
          {{ receipt ? "Receipt" : "Invoice" }} from
          {{ formDetails.companyName }}
        </div>
        <div class="flex items-center gap-4">
          <easiButton
            variant="outlined"
            class="gap-2"
            :loading="downloading"
            @click="sendInvoice(true)"
          >
            <img src="@/assets/icons/newDownload.svg" />
            Download</easiButton
          >
        </div>
      </div>
      <div v-else class="sm:flex justify-between">
        <div class="text-grey font-bold text-2xl mb-5 md:mb-0">
          {{ receipt ? "Receipt" : "Invoice" }} Preview
        </div>
        <div class="flex items-center gap-4">
          <div
            v-if="formDetails && formDetails.status && !receipt"
            :class="[
              getStatusColor(formDetails.status),

              'px-4 py-1.5 rounded-full h-full flex items-center capitalize text-center  ',
            ]"
            getStatusColor
          >
            <span class="uppercase">
              {{ formDetails.status }}
            </span>
          </div>
          <easiButton
            v-if="addForm"
            variant="outlined"
            :loading="saving"
            class="gap-2"
            @click="
              emit(
                'save',
                formDetails && formDetails.invoiceNo
                  ? formDetails.invoiceNo
                  : formDetails.receiptNo
                  ? formDetails.receiptNo
                  : invoiceNumber
              )
            "
          >
            <img src="@/assets/icons/Save.svg" />
            Save</easiButton
          >
          <easiButton
            v-if="!addForm"
            variant="outlined"
            :loading="downloading"
            class="gap-2"
            @click="sendInvoice(true)"
          >
            <img src="@/assets/icons/newDownload.svg" />
            Download</easiButton
          >
          <easiButton
            v-if="!addForm"
            class="gap-2 font-semibold"
            @click="handleSend"
          >
            <img src="@/assets/icons/Paper_Plane.svg" />

            Send</easiButton
          >
        </div>
      </div>
      <div
        v-if="formDetails"
        class="mt-12 pb-12 border border-outlineGray rounded-2xl"
      >
        <div
          class="px-3 md:px-7 flex flex-wrap w-full items-center justify-between border-b border-outlineGray pt-5 pb-3"
        >
          <!-- <img src="@/assets/icons/CompanyLogo.svg" /> -->
          <div></div>
          <p
            v-if="!receipt"
            class="text-primary-deep text-3xl font-medium self-end"
          >
            {{ receipt ? "Receipt" : "Invoice" }}
          </p>
          <p class="text-dark-800 text-lg mt-2 md:mt-0">
            {{
              formDetails && formDetails.invoiceNo
                ? formDetails.invoiceNo
                : formDetails.receiptNo
                ? formDetails.receiptNo
                : invoiceNumber
            }}
          </p>
        </div>
        <div class="mt-9 px-3 md:px-7 flex flex-col gap-4">
          <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div
              class="p-2 rounded-xl border border-newAsh bg-grayLight flex flex-col"
            >
              <p class="text-dark-200 font-normal text-base">Full Name</p>
              <p
                v-if="formDetails.client && formDetails.client.firstName"
                class="break-all text-grey text-lg font-semibold"
              >
                {{ formDetails.client.firstName }}
                {{ formDetails.client.lastName }}
              </p>
              <p v-else class="break-all text-grey text-lg font-semibold">
                {{ formDetails.firstName }} {{ formDetails.lastName }}
              </p>
            </div>
            <div
              class="p-2 rounded-xl border border-newAsh bg-grayLight flex flex-col"
            >
              <p class="text-dark-200 font-normal text-base">
                Billing Address:
              </p>
              <p class="break-all text-grey text-lg font-semibold">
                {{
                  formDetails.billingAddress
                    ? formDetails.billingAddress
                    : formDetails.address
                }}
              </p>
            </div>
          </div>
          <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div
              class="p-2 rounded-xl border border-newAsh bg-grayLight flex flex-col"
            >
              <p class="text-dark-200 font-normal text-base">Email Address:</p>
              <p class="break-all text-grey text-lg font-semibold">
                {{
                  formDetails.client && formDetails.client.email
                    ? formDetails.client.email
                    : formDetails.email
                }}
              </p>
            </div>
            <div
              class="p-2 rounded-xl border border-newAsh bg-grayLight flex flex-col"
            >
              <p class="text-dark-200 font-normal text-base">
                Date of {{ receipt ? "Receipt" : "Invoice" }}
              </p>
              <p class="break-all text-grey text-lg font-semibold">
                {{
                  formDetails.createdAt
                    ? formatDateString(formDetails.createdAt)
                    : new Date().toDateString()
                }}
              </p>
            </div>
          </div>
          <div class="grid gap-4 grid-cols-1 md:grid-cols-2">
            <div
              class="p-2 rounded-xl border border-newAsh bg-grayLight flex flex-col"
            >
              <p class="text-dark-200 font-normal text-base">
                Contact Phone Number
              </p>
              <p class="break-all text-grey text-lg font-semibold">
                {{
                  formDetails.client && formDetails.client.phone
                    ? formDetails.client.phone
                    : formDetails.phone
                }}
              </p>
            </div>
            <div
              class="p-2 rounded-xl border border-newAsh bg-grayLight flex flex-col"
            >
              <p class="text-dark-200 font-normal text-base">
                {{ receipt ? "Receipt" : "Invoice" }} NO
              </p>
              <p class="break-all text-grey text-lg font-semibold">
                {{
                  formDetails.invoiceNo ? formDetails.invoiceNo : invoiceNumber
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="mt-10 px-3 md:px-7">
          <PreviewTable
            :itemsList="formDetails.items"
            :details="{
              tax: formDetails.autoVat || formDetails.tax,
              discount: Number(formDetails.discount),
              total: formDetails.total || formDetails.totalAmount,
            }"
          />
        </div>
        <div class="flex justify-between items-end pr-12">
          <div class="mt-2 px-14">
            <p
              class="font-medium text-sm mb-3"
              :class="receipt ? 'text-secondary' : ' text-grey'"
            >
              {{ receipt ? "Receipt" : "Invoice" }} Note
            </p>
            <div
              class="rounded-2xl border inline-block text-sm border-outlineGray px-3 py-5 break-all"
            >
              {{
                formDetails.note ? formDetails.note : formDetails.notes || "N/A"
              }}
            </div>
          </div>

          <div
            v-if="
              showPay &&
              formDetails.status &&
              formDetails.status.toLowerCase() === 'unpaid'
            "
            class="w-5/12 mr-4"
          >
            <easiButton block class="gap-2 w-full" @click="manualModal = true">
              Record Payment
            </easiButton>
          </div>
          <div
            v-if="
              showPay &&
              formDetails.status &&
              formDetails.status.toLowerCase() === 'unpaid'
            "
            class="w-5/12"
          >
            <easiButton block class="gap-2 w-full" @click="payNow">
              Pay now</easiButton
            >
          </div>
        </div>
      </div>

      <p v-if="publicPage" class="text-secondary text-center text-sm mt-12">
        Invoice generated by Eazipay Ltd.
      </p>
    </div>
    <easiModal
      :isBorder="false"
      v-if="transferNotification"
      @close="transferNotification = false"
    >
      <div class="flex flex-col items-center justify-center px-4 w-full gap-8">
        <span class="text-base text-center mt-9 font-medium text-dark-800"
          >Wallet will be updated as soon as the <br />
          money is received.</span
        >
        <img src="@/assets/icons/wallet-icon.svg" alt="" />
      </div>

      <hr class="mt-8 mb-4 border border-dividerColor text-dividerColor" />
      <div class="flex gap-x-6 w-full my-6 justify-center items-center">
        <easiButton
          color="primary"
          @click="transferNotification = false"
          class="w-80 md:w-40 focus:outline-none text-sm py-3 px-4 bg-primary rounded-lg text-white"
        >
          <span class="text-sm"> Dismiss</span>
        </easiButton>
      </div>
    </easiModal>
    <easiAlert
      v-if="bankTransferModal"
      @close="bankTransferModal = false"
      noIcon
      title="Pay with Bank Transfer"
    >
      <div class="flex flex-col gap-4 px-8">
        <div
          class="cursor-pointer bg-newGrey3 rounded-2xl p-4 flex flex-col gap-6"
        >
          <div class="flex flex-col">
            <p class="text-newGrey4">Bank Name:</p>
            <p class="font-semibold text-newGrey2 text-base">
              {{ bankDetails.bankName || "N/A" }}
            </p>
          </div>
          <div class="flex flex-col">
            <p class="text-newGrey4">Account Name:</p>
            <p class="font-semibold text-newGrey2 text-base">
              {{ bankDetails.accountName || "N/A" }}
            </p>
          </div>
          <div class="flex justify-between">
            <div class="flex flex-col">
              <p class="text-newGrey4">Account No:</p>
              <p class="font-semibold text-newGrey2 text-base">
                {{ bankDetails.accountNumber || "N/A" }}
              </p>
            </div>
            <div
              @click="copyText1()"
              class="flex items-center gap-3 cursor-pointer bg-white rounded-full px-6 py-3"
            >
              <img src="@/assets/icons/copy-new.svg" alt="" />

              <p class="text-xs text-primary-deep">Copy</p>
            </div>
          </div>
        </div>
      </div>
      <div class="pb-4 px-6 mt-8">
        <easiButton
          size="medium"
          :loading="loading"
          @click="
            () => {
              bankTransferModal = false;
              transferNotification = true;
            }
          "
          block
          >I have made the transfer</easiButton
        >
      </div>
    </easiAlert>
    <easiAlert
      v-if="paymentModal"
      @close="paymentModal = false"
      noIcon
      :title="''"
      :caption="`Select an option for payment`"
    >
      <div class="flex flex-col gap-4 px-8">
        <div
          class="cursor-pointer rounded-2xl py-4 px-6 flex justify-between"
          :class="
            activePayment === 'bank'
              ? 'border border-primary bg-mint'
              : 'bg-newGrey3'
          "
          @click="activePayment = 'bank'"
        >
          <div class="flex items-center gap-3">
            <div
              class="bg-white rounded-2xl h-12 w-12 flex items-center justify-center"
            >
              <img src="@/assets/icons/bankNew.svg" alt="" />
            </div>

            <p class="text-newGrey2 text-base">Pay with Bank Transfer</p>
          </div>
          <img
            v-if="activePayment === 'bank'"
            src="@/assets/icons/activeCheck.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/inActive.svg" alt="" />
        </div>
        <div
          class="cursor-pointer rounded-2xl py-4 px-6 flex justify-between"
          @click="activePayment = 'paystack'"
          :class="
            activePayment === 'paystack'
              ? 'border border-primary bg-mint'
              : ' bg-newGrey3'
          "
        >
          <div class="flex items-center gap-3">
            <div
              class="bg-white rounded-2xl h-12 w-12 flex items-center justify-center"
            >
              <img src="@/assets/icons/paystackNew.svg" alt="" />
            </div>

            <p class="text-newGrey2 text-base">Pay with Paystack</p>
          </div>
          <img
            v-if="activePayment === 'paystack'"
            src="@/assets/icons/activeCheck.svg"
            alt=""
          />
          <img v-else src="@/assets/icons/inActive.svg" alt="" />
        </div>
      </div>
      <div class="pb-4 px-6 mt-8">
        <easiButton
          size="medium"
          :loading="isGenerating"
          @click="handleProceed"
          block
          >Proceed</easiButton
        >
      </div>
    </easiAlert>
    <easiAlert
      v-if="paystackModal"
      @close="paystackModal = false"
      noIcon
      :title="''"
      :caption="''"
    >
      <div class="text-center">
        <span
          @click="copyText(link)"
          class="text-hoverColor font-medium text-sm text-center"
          >{{ link }}</span
        >

        <div class="flex mt-2 gap-x-5 justify-center items-center">
          <div
            @click="copyText(link)"
            class="bg-white rounded-xl px-4 py-2 cursor-pointer flex items-center gap-3"
          >
            <img src="../../../assets/icons/copy.svg" alt="" />
            <span class="text-dark-800 font-bold inline-block text-sm"
              >Copy</span
            >
          </div>
        </div>
        <div class="pb-4 px-6 mt-8 space-y-4">
          <easiButton
            size="medium"
            :loading="isGenerating"
            @click="
              () => {
                paystackModal = false;
                transferNotification = true;
              }
            "
            block
            >I have made the transfer</easiButton
          >
          <easiButton
            variant="outlined"
            size="medium"
            :loading="isGenerating"
            @click="paystackModal = false"
            block
            >Cancel</easiButton
          >
        </div>
      </div>
    </easiAlert>
    <easiAlert
      v-if="manualModal"
      title="Record Invoice"
      caption=""
      @close="manualModal = false"
      noIcon
    >
      <div class="p-5 flex flex-col gap-4">
        <easiSelectInput2
          :options="[
            { label: 'Part', value: 'part' },
            { label: 'Fully', value: 'fully' },
          ]"
          :value="manual.type"
          @update="manual.type = $event"
          placeholder="Select type"
          name="type"
          class="mt-3"
          :bookKeeping="true"
          :error="errorRules.type"
        ></easiSelectInput2>
        <easiSelectInput2
          :options="[
            { label: 'Cash', value: 'cash' },
            { label: 'POS', value: 'pos' },
            { label: 'Cheque', value: 'cheque' },
            { label: 'Bank Transfer', value: 'bank_transfer' },
          ]"
          :value="manual.method"
          @update="manual.method = $event"
          placeholder="Select method"
          name="type"
          class="mt-3"
          :bookKeeping="true"
          :error="errorRules.method"
        ></easiSelectInput2>

        <easiTextInput
          v-if="manual.type !== 'fully'"
          label="Amount"
          placeholder="Amount"
          name="amount"
          type="number"
          class=""
          v-model="manual.amount"
          :error="errorRules.amount"
        />

        <div class="cursor-pointer flex items-center gap-4 mt-5 text-sm">
          <input
            class="w-6 h-6 rounded-md"
            type="checkbox"
            name="checko"
            id=""
            :checked="manual.taxesPayByClient"
            :value="false"
            @change="manual.taxesPayByClient = !manual.taxesPayByClient"
          />
          <label
            for="checko"
            style="margin-top: 1px"
            class="cursor-pointer"
            @click="manual.taxesPayByClient = !manual.taxesPayByClient"
          >
            Was Tax paid?
          </label>
        </div>
        <div class="flex justify-center">
          <easiButton
            :loading="isRecording"
            class="gap-2 font-semibold"
            @click="handleRecord"
          >
            Submit</easiButton
          >
        </div>
      </div>
    </easiAlert>
    <easiAlert
      v-if="sendModal"
      :title="receipt ? 'Send Receipt' : 'Send Invoice'"
      caption=""
      @close="sendModal = false"
      noIcon
    >
      <div class="flex flex-col w-11/12 gap-6 mx-auto">
        <easiTextInput
          label="email"
          placeholder="Client's email address"
          name="email"
          type="email"
          class=""
          v-model="clientEmail"
          :error="errorRules.clientEmail"
        />
        <div v-if="!receipt" class="flex flex-col gap-2">
          <p class="text-xs text-grey">Copy link</p>
          <div
            class="rounded-xl bg-newAsh border-outlineGray px-4 py-3 flex justify-between items-center"
          >
            <p class="break-all mr-2">{{ url }}</p>
            <div
              @click="copyText()"
              class="flex gap-3 cursor-pointer bg-white rounded-full px-6 py-3"
            >
              <img src="@/assets/icons/copy-new.svg" alt="" />

              <p class="text-xs text-primary-deep">Copy</p>
            </div>
          </div>
        </div>

        <div class="mt-2">
          <easiButton size="medium" @click="sendInvoice" block
            >Send {{ receipt ? "Receipt" : "Invoice" }}
          </easiButton>
        </div>
      </div>
    </easiAlert>
    <easiAlert
      v-if="showSentInvoice"
      title="Save Invoice"
      caption="Invoice sent successfully!"
    >
      <div class="w-11/12 mx-auto">
        <easiButton size="medium" @click="showSentInvoice = false" block
          >Dismiss</easiButton
        >
      </div>
    </easiAlert>
    <easiAlert
      v-if="successModal"
      title="Invoice Recorded"
      caption="Invoice recorded successfully!"
    >
      <div class="w-11/12 mx-auto">
        <easiButton
          size="medium"
          @click="
            successModal = false;
            $router.go(-1);
          "
          block
          >Dismiss</easiButton
        >
      </div>
    </easiAlert>
    <easiLoading v-if="loading" @close="loading = false"> </easiLoading>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, computed } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";
("");
import { SEND_INVOICE } from "@/services/book-keeping/invoice";

const { formatDateString, downloadBase64 } = helperFunctions;
const props = defineProps({
  addForm: {
    type: Boolean,
    default: false,
  },
  showPay: Boolean,
  receipt: Boolean,

  saving: {
    type: Boolean,
    default: false,
  },

  publicPage: {
    type: Boolean,
    default: false,
  },
  detailPage: {
    type: Boolean,
    default: false,
  },
  createdInvoice: String,
  createdCompanyId: String,
  formDetails: Object,
  bankDetails: Object,
});

import { useToast } from "vue-toastification";
import { useDataStore } from "@/stores/data.js";
import { useRoute } from "vue-router";

const transferNotification = ref(false);
const store = useDataStore();
const { query, mutate } = store;
const downloading = ref(false);
const loading = ref(false);
const isRecording = ref(false);

const showSentInvoice = ref(false);
function getStatusColor(status) {
  const obj = {
    unPaid: `text-error bg-error-background`,
    paid: `text-success bg-statusGreen`,
  };

  return obj[status] || "";
}
const hostUrl = window.location.host;
const route = useRoute();

const invoiceId = computed(() => route.params.id);
const successModal = ref(false);
const handleRecord = async () => {
  try {
    isRecording.value = true;
    const { type, amount, method, taxesPayByClient } = manual;
    const payload = {
      type,
      amount: Number(amount),
      method,
      taxesPayByClient,
      id: invoiceId.value,
    };
    if (type === "fully") {
      delete payload.amount;
    }
    let res = await mutate({
      endpoint: "RecordPaymentBookKeepingInvoices",
      data: {
        input: payload,
      },
      service: "BOOKKEEPING",
    });
    console.log("res is", res);
    if (res.success) {
      manualModal.value = false;
      successModal.value = true;
    }
  } catch (e) {
    console.log(e);
  } finally {
    isRecording.value = false;
  }
};
const url = computed(() => {
  return `${hostUrl}/payInvoice?id=${
    props.formDetails._id ? props.formDetails._id : props.createdInvoice
  }&companyId=${
    props.formDetails ? props.formDetails.companyId : props.createdCompanyId
  }`;
});
function copyText(val) {
  navigator.clipboard.writeText(val ? val : url.value);
  toast.info("Copied!");
}
const clientEmail = ref("");
const manual = reactive({
  type: "",
  amount: "",
  taxesPayByClient: false,
});
const errorRules = reactive({
  clientEmail: false,
  type: false,
  amount: false,
});
const sendModal = ref(false);

const handleSend = async () => {
  sendModal.value = true;
};
const sendInvoice = async (download) => {
  if (!clientEmail.value && download !== true)
    return (errorRules.clientEmail = "Please add an email address");
  download === true ? (downloading.value = true) : (loading.value = true);
  const payload = {
    downloadInvoiceId: props.formDetails._id
      ? props.formDetails._id
      : props.createdInvoice,
    email: clientEmail.value,
  };
  if (download === true) {
    payload.email = "";
  }
  try {
    const res = await SEND_INVOICE(payload, props.receipt);
    if (res.success === true) {
      if (download === true) {
        const base64 = res.data.base64;
        const fileName = res.data.fileName;
        downloadBase64(base64, fileName);
      } else {
        sendModal.value = false;
        showSentInvoice.value = true;
        clientEmail.value = "";
      }
    } else {
      toast.error(
        props.receipt ? "Receipt Sending failed." : "Invoice Sending failed."
      );
    }
  } catch (e) {
    toast.error(e.message);
  } finally {
    loading.value = false;
    downloading.value = false;
  }
};
const toast = useToast();
const companyData = computed(() => store.getCompanyDetails);
const paymentModal = ref(false);
const paystackModal = ref(false);

const bankTransferModal = ref(false);
const args = reactive({
  accountNumber: 232334444333,
});
const invoiceNumber = ref("");
onMounted(() => {
  generateInvoiceNumber();
});
const generateRandomNumber = () => {
  const currentTime = new Date().getTime();
  const minLength = 4;
  const maxLength = 6;

  // Generate a random length between minLength and maxLength
  const randomLength =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;

  // Use the last n digits of the current time as a random number, where n is the random length
  const randomNLengthNumber = currentTime % Math.pow(10, randomLength);
  return randomNLengthNumber;
};
const checkIfInvoiceExists = () => {
  return false;
};
const generateInvoiceNumber = () => {
  const length = Number(generateRandomNumber());
  const companyName =
    companyData.value &&
    companyData.value.company &&
    companyData.value.company.companyName
      ? companyData.value.company.companyName
      : "Invoice";
  const arr = companyName ? companyName.split(" ") : [];

  if (arr && arr.length && arr[0].length >= 4) {
    const firstFour = arr[0].substring(0, 4);

    const result = checkIfInvoiceExists(`${firstFour}-${length}`)
      ? `${firstFour}-${length + 1}`
      : `${firstFour}-${length}`;
    invoiceNumber.value = result;
  } else {
    const sub = arr[0].length;
    const pre = arr[0].substring(0, sub);
    // const length = Number(this.allEmployees.count + 1);
    const result = checkIfInvoiceExists(`${pre}-${length}`)
      ? `${pre}-${length + 1}`
      : `${pre}-${length}`;
    invoiceNumber.value = result;
  }
};
function copyText1() {
  navigator.clipboard.writeText(args.accountNumber);
  toast.info("Copied!");
}
const link = ref(false);
const isGenerating = ref(false);

const generatePaystackUrl = async () => {
  try {
    const payload = {
      invoiceId: props.formDetails._id
        ? props.formDetails._id
        : props.createdInvoice,
      // companyId:   props.formDetails ? props.formDetails.companyId : props.createdCompanyId,
    };
    isGenerating.value = true;
    let res = await mutate({
      endpoint: "PayInvoice",
      service: "PAYROLL",
      data: {
        input: payload,
      },
    });
    if (res.success) {
      console.log(res.data.paymentResponse);
      link.value = res.data.paymentResponse.authorizationUrl;
      paymentModal.value = false;
      paystackModal.value = true;
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
  } finally {
    isGenerating.value = false;
  }
};
const manualModal = ref(false);
const handleProceed = () => {
  if (activePayment.value === "bank") {
    paymentModal.value = false;
    bankTransferModal.value = true;
  } else if (activePayment.value === "manual") {
    paymentModal.value = false;
    manualModal.value = true;
  } else {
    generatePaystackUrl();
  }
};
const payNow = () => {
  paymentModal.value = true;
};
const activePayment = ref("bank");
const emit = defineEmits(["save"]);
import PreviewTable from "./PreviewTable";
</script>

<style lang="scss" scoped></style>
