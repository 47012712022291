<template>
  <div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead>
        <tr>
          <th
            v-for="(header, index) in tableHeaders"
            :key="index"
            class="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            {{ header.title }}
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(row, rowIndex) in visibleData" :key="rowIndex">
          <td class="px-6 py-4 whitespace-nowrap">{{ rowIndex + 1 }}</td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{ row.name ? row.name : row.itemName }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap">{{ row.quantity }}</td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{ formatAmount(row.unit ? row.unit : row.amount) }}
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{
              formatAmount(
                row.unit ? row.unit * row.quantity : row.amount * row.quantity
              )
            }}
          </td>
        </tr>

        <!-- Last row with first 2 items removed -->
        <tr class="noborder"
        v-if="details.autoVat ? details.autoVat : details.tax"
        
        >
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap"
          >TAX</td>
          <td 
          
          class="px-6 py-4 whitespace-nowrap">
            {{ (details.autoVat ? details.autoVat : details.tax)}}%
          </td>
        </tr>
        <tr class="noborder">
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap">DISCOUNT</td>
          <td class="px-6 py-4 whitespace-nowrap">
            {{ formatAmount(details.discount) }}
          </td>
        </tr>
        <tr class="noborder">
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap noborder"></td>
          <td class="px-6 py-4 whitespace-nowrap bg-newAsh border border-gray">
            TOTAL
          </td>
          <td
            class="px-6 py-4 whitespace-nowrap bg-newAsh border border-gray font-bold"
          >
            {{ formatAmount(details.total ) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { helperFunctions } from "@/composable/helperFunctions";

const { formatAmount, formatDate } = helperFunctions;
const props = defineProps({
  itemsList: Object,
  details: Object,
});
const visibleData = computed(()=>props.itemsList);

const tableHeaders = [
  {
    style: ``,
    title: "S/N",
    field: "serial",
    type: "serial",
  },
  {
    style: ``,
    title: "NAME OF ITEM",
    field: "itemName",
    type: "itemName",
  },
  {
    style: ``,
    title: "QUANTITY",
    field: "quantity",
  },
  {
    style: ``,
    title: "UNIT PRICE",
    field: "amount",
  },
  {
    style: ``,
    title: "AMOUNT",
    field: "amount",
    format: (val) => {
      return val ? formatAmount(val) : "--";
    },
  },
];
</script>

<style lang="scss" scoped>
th {
  border-right: 1px solid #e7e8e7;
  background: #e9efee;
  color: #515251;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  white-space: nowrap;

  &:first-child {
    border-top-left-radius: 16px;
  }

  &:last-child {
    border-top-right-radius: 16px;
    border-right: none;
  }
}

td {
  border-right: 1px solid #e7e8e7;
  border-left: 1px solid #e7e8e7;

  border-bottom: 1px solid #ededed;
  color: #515251;
  padding-top: 15px;
  padding-bottom: 15px;
}
.noborder {
  border: none !important;
}
</style>
